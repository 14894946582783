import consumer from "../channels/consumer"

$(document).ready(function () {

  // Open connection only for GalleriesController#show
  if ($("body").attr('class') == 'galleries-show') {
    consumer.subscriptions.create({channel: "GalleryChannel", id: $("#gallery").data().id}, {
        connected() {
          // Called when the subscription is ready for use on the server
          console.info("connected");
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
          console.info("disconnected");
        },

        received(data) {
          // Called when there's incoming data on the websocket for this channel
          console.info("received data");
          $("#progress_bar").css("width", `${data.progress}%`).attr("aria-valuenow", data.progress)

          // Change progress bar color when finish
          if (data.progress == 100) {
            $("#progress_bar").removeClass("bg-danger").addClass("bg-success");
          }

          // Update image
          if (data.image !== undefined) {
            // Update gallery image
            $("#image_" + data.image.id).find("a").attr("href", data.image.urls.Gallery);

            // Update thumbnail image
            $("#image_" + data.image.id).find(".spinner").remove()

            // Hide spinner
            $("#image_" + data.image.id).find("img").attr("src", data.image.urls.Thumbnail);

            // Show thumbnail image
            $("#image_" + data.image.id).find("img").css("display", "");
          }

          // Change progress bar title when finished
          if (data.title !== undefined) {
            $("#progress_title").html(data.title);
          }
        }
      });
  }
});
