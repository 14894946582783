// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import Rails from "@rails/ujs"
import "channels"
import "sbadmin2/sb-admin-2.min"
import "sbadmin2/jquery.easing.min"
import "sbadmin2/bootstrap.bundle.min"
import "common/jquery.toast.min"

require("trix")
require("@rails/actiontext")

Rails.start()
