import consumer from "../channels/consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    console.info("connected to notification channel");
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.info("disconnected from notification channel");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.info("received notification data");
    console.info(data);

    if (data.type == 'success') {
      color = '#00BB00';
    }

    $.toast({heading: data.title, text: data.message, bgColor: data.bg_color, position: "top-right", hideAfter: false})

    // Called when there's incoming data on the websocket for this channel
  }
});

